import { attributes } from "src/constants";

export const mainFields = [
  {
    attribute: attributes["Устав"],
    title: "Устав",
    label: "Устав",
    fileType: "uploadOOO",
  },
  {
    attribute: attributes["Решение"],
    title: "Решение",
    label: "Решение",
    fileType: "uploadOOO",
  },
  {
    attribute: attributes["Юр. адрес"],
    title: "Юр. адрес",
    label: "Юр. адрес",
    fileType: "uploadOOO",
  },
];

export const ownerFileFields = [
  {
    attribute: attributes["Скан паспорта"],
    title: "Скан паспорта",
    label: "Паспорт",
    fileType: "uploadOOO",
  },
  {
    attribute: attributes["Скан прописки"],
    title: "Прописка",
    label: "Прописка",
    fileType: "uploadOOO",
  },
  {
    attribute: attributes["Скан СНИЛС"],
    title: "Скан СНИЛС",
    label: "СНИЛС",
    fileType: "uploadOOO",
  },
];

export const addressFieldsNames = {
  [attributes["Полный адрес"]]: attributes["Полный адрес"],
  [attributes["Субъект"]]: attributes["Субъект"],
  [attributes["Код терр. ФНС"]]: attributes["Код терр. ФНС"],
  [attributes["ОКТМО"]]: attributes["ОКТМО"],
  [attributes["Код рег. органа"]]: attributes["Код рег. органа"],
  [attributes["Муниципальное образование (верхнее)"]]: attributes["Муниципальное образование (верхнее)"],
  [attributes["Наименование муниципального образования (верхнее)"]]:
    attributes["Наименование муниципального образования (верхнее)"],
  [attributes["Муниципальное образование (нижнее)"]]: attributes["Муниципальное образование (нижнее)"],
  [attributes["Наименование муниципального образования (нижнее)"]]:
    attributes["Наименование муниципального образования (нижнее)"],
  [attributes["Населенный пункт"]]: attributes["Населенный пункт"],
  [attributes["Наименование населенного пункта"]]: attributes["Наименование населенного пункта"],
  [attributes["Элемент планировочной структуры"]]: attributes["Элемент планировочной структуры"],
  [attributes["Наименование элемента планировочной структуры"]]:
    attributes["Наименование элемента планировочной структуры"],
  [attributes["Элемент улично-дорожной сети"]]: attributes["Элемент улично-дорожной сети"],
  [attributes["Наименование элемента улично-дорожной сети"]]: attributes["Наименование элемента улично-дорожной сети"],
  [attributes["Здание/сооружение (первый столбец)"]]: attributes["Здание/сооружение (первый столбец)"],
  [attributes["Номер здания/сооружения (первый столбец)"]]: attributes["Номер здания/сооружения (первый столбец)"],
  [attributes["Здание/сооружение (второй столбец)"]]: "home_short2",
  [attributes["Номер здания/сооружения (второй столбец)"]]: "home_num2",
  [attributes["Здание/сооружение (третий столбец)"]]: "home_short3",
  [attributes["Номер здания/сооружения (третий столбец)"]]: "home_num3",
  [attributes["Квартира/офис"]]: attributes["Квартира/офис"],
  [attributes["Номер квартиры/офиса"]]: attributes["Номер квартиры/офиса"],
  [attributes["Помещение"]]: attributes["Помещение"],
  [attributes["Номер помещения"]]: attributes["Номер помещения"],

  // вспомогательные поля
  [attributes["Адрес для документов"]]: attributes["Адрес для документов"],
  [attributes["Регион (сокр.)"]]: attributes["Регион (сокр.)"],
  [attributes["Наименование региона"]]: attributes["Наименование региона"],
  [attributes["Регион (полн.)"]]: attributes["Регион (полн.)"],
};
