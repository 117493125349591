import { IForm } from "src/helpers/types/Form";
import { attributes, formsNames } from "src/constants";
import { IDadataAddressData, IDadataOktmo } from "src/helpers/types/Dadata";
import { SceneType } from "src/helpers/types/Scene";

export interface IProps extends IForm {
  scene: SceneType | undefined;
  className?: string;
  data: Record<string, any>;
  fieldsNames: Record<string, string>;
  addressForDocs: string;
  addressChanged: boolean;
  changeFieldValue: (form: string, field: string, value: string) => void;
  autoCompleteData: { address: Array<{ data: IDadataAddressData; unrestricted_value: string; value: string }> };
  extended: boolean;
  loadSonoArea: (tax_office: string) => Promise<string>;
  getOktmoInfo: (code: string) => Promise<IDadataOktmo>;
  city: string;
  title: string;
  showTitle: boolean;
  showFinalAddress: boolean;
  showRegistrOrgan: boolean;
  custom_address: string;
  appartment_abbr: string;
  pom: string;
  region: string;
  userCompanyId: number;
  customValidate: (
    values: Record<string, any>,
    errors: Record<string, string>,
    fieldsNames: Record<string, string>
  ) => Record<string, string>;
}

export interface IState {
  hasProblemOktmo: boolean;
  house_fias_id: string;
  addressChanged: boolean;
  tooltipVisible: boolean;
  // По умолчанию все поля, в которые подставляются данные из дадаты, заблокированы.
  // Если какая-то информация с дадаты не пришла, поле разблокируется для ручного ввода.
  disabledFields: string[];
}

export const defaultProps = {
  form: formsNames["registration"],
  extended: false,
  showTitle: true,
  showRegistrOrgan: true,
  title: "Адрес регистрации",
  fieldsNames: {
    [attributes["Полный адрес"]]: attributes["Полный адрес"],
    [attributes["Субъект"]]: attributes["Субъект"],
    [attributes["Код терр. ФНС"]]: attributes["Код терр. ФНС"],
    [attributes["ОКТМО"]]: attributes["ОКТМО"],
    [attributes["Код рег. органа"]]: attributes["Код рег. органа"],
    [attributes["Муниципальное образование (верхнее)"]]: attributes["Муниципальное образование (верхнее)"],
    [attributes["Наименование муниципального образования (верхнее)"]]:
      attributes["Наименование муниципального образования (верхнее)"],
    [attributes["Муниципальное образование (нижнее)"]]: attributes["Муниципальное образование (нижнее)"],
    [attributes["Наименование муниципального образования (нижнее)"]]:
      attributes["Наименование муниципального образования (нижнее)"],
    [attributes["Населенный пункт"]]: attributes["Населенный пункт"],
    [attributes["Наименование населенного пункта"]]: attributes["Наименование населенного пункта"],
    [attributes["Элемент планировочной структуры"]]: attributes["Элемент планировочной структуры"],
    [attributes["Наименование элемента планировочной структуры"]]:
      attributes["Наименование элемента планировочной структуры"],
    [attributes["Элемент улично-дорожной сети"]]: attributes["Элемент улично-дорожной сети"],
    [attributes["Наименование элемента улично-дорожной сети"]]:
      attributes["Наименование элемента улично-дорожной сети"],
    [attributes["Здание/сооружение (первый столбец)"]]: attributes["Здание/сооружение (первый столбец)"],
    [attributes["Номер здания/сооружения (первый столбец)"]]: attributes["Номер здания/сооружения (первый столбец)"],
    [attributes["Здание/сооружение (второй столбец)"]]: attributes["Здание/сооружение (второй столбец)"],
    [attributes["Номер здания/сооружения (второй столбец)"]]: attributes["Номер здания/сооружения (второй столбец)"],
    [attributes["Здание/сооружение (третий столбец)"]]: attributes["Здание/сооружение (третий столбец)"],
    [attributes["Номер здания/сооружения (третий столбец)"]]: attributes["Номер здания/сооружения (третий столбец)"],
    [attributes["Квартира/офис"]]: attributes["Квартира/офис"],
    [attributes["Номер квартиры/офиса"]]: attributes["Номер квартиры/офиса"],
    [attributes["Помещение"]]: attributes["Помещение"],
    [attributes["Номер помещения"]]: attributes["Номер помещения"],

    // вспомогательные поля
    [attributes["Адрес для документов"]]: attributes["Адрес для документов"],
    [attributes["Наименование региона"]]: attributes["Наименование региона"],
    [attributes["Регион (сокр.)"]]: attributes["Регион (сокр.)"],
    [attributes["Регион (полн.)"]]: attributes["Регион (полн.)"],
  },
};
