import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { cn } from "src/helpers/bem";
import "./Registration.scss";
import {
  AInput,
  AAutoComplete,
  ASelect,
  dadataAddressFormat,
  getAddressString,
  isFederalCity,
  formatAddressObject,
  isAdmin,
  isScene,
} from "src/helpers";
import { loadSonoArea, getOktmoInfo } from "src/redux/input";
import { message } from "antd";
import { connect } from "react-redux";
import { Field, reduxForm, change, submit, formValueSelector, isDirty } from "redux-form";
import validate from "./validate";
import { attributes, errorsTypes, Forms } from "src/constants";
import { Tooltip } from "src/components";
import { getInitialValues } from "./initialValues";
import classNames from "classnames/dedupe";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps, IState } from "./interface";

const sf = cn("site-form");
const b = cn("registration-form");

const OKTMO_NUMBERS_WITH_SUPPORT_MESSAGE = [
  45941000, 45948000, 45966000, 45943000, 45959000, 45931000, 45963000, 45932000, 45322000, 45326000, 45909000,
];

const FORM_NAMES_WITH_OKTMO_SUPPORT_MESSAGE: Array<Forms> = [
  "OOOregistration",
  "registration",
  "changeOOORegistration",
];

export function getSelectedDadataInfo({
  selectedAddress,
  autoCompleteData,
  fieldsNames,
}: {
  selectedAddress: string;
  autoCompleteData: IProps["autoCompleteData"];
  fieldsNames: IProps["fieldsNames"];
}) {
  // найдем именно ту подсказку среди всех, которую выбрал пользователь
  const selectedHint = autoCompleteData[fieldsNames[attributes["Полный адрес"]] as "address"]?.filter(
    (item) => item.value === selectedAddress
  )?.[0];

  return selectedHint;
}

const areaOptions = [
  { value: "", title: "" },
  { value: "1", title: "1 — муниципальный район" },
  { value: "2", title: "2 — городской округ" },
  { value: "3", title: "3 — внутригородская территория города федерального значения" },
  { value: "4", title: "4 — муниципальный округ" },
];

const subareaOptions = [
  { value: "", title: "" },
  { value: "1", title: "1 — городское поселение" },
  { value: "2", title: "2 — сельское поселение" },
  { value: "3", title: "3 — межселенная территория в составе муниципального района" },
  { value: "4", title: "4 — внутригородской район городского округа" },
];

const flatOptions = [
  { value: "кв.", title: "кв." },
  { value: "ком.", title: "ком." },
  { value: "пом.", title: "пом." },
  { value: "офис", title: "офис" },
  { value: "помещ.", title: "помещ." },
  { value: "подв.", title: "подв." },
];

const roomOptions = [
  { value: "ком.", title: "ком." },
  { value: "пом.", title: "пом." },
  { value: "офис", title: "офис" },
  { value: "помещ.", title: "помещ." },
  { value: "подв.", title: "подв." },
];

class RegistrationForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.fillRegistrationField = this.fillRegistrationField.bind(this);

    this.state = {
      hasProblemOktmo: false,
      house_fias_id: "initial_value",
      addressChanged: false,
      tooltipVisible: false,
      // По умолчанию все поля, в которые подставляются данные из дадаты, заблокированы.
      // Если какая-то информация с дадаты не пришла, поле разблокируется для ручного ввода.
      disabledFields: [
        attributes["Субъект"],
        attributes["Код терр. ФНС"],
        attributes["Код рег. органа"],
        attributes["Населенный пункт"],
        attributes["Наименование населенного пункта"],
        attributes["Элемент планировочной структуры"],
        attributes["Наименование элемента планировочной структуры"],
        attributes["Элемент улично-дорожной сети"],
        attributes["Наименование элемента улично-дорожной сети"],
        attributes["Здание/сооружение (первый столбец)"],
        attributes["Номер здания/сооружения (первый столбец)"],
        attributes["Здание/сооружение (второй столбец)"],
        attributes["Номер здания/сооружения (второй столбец)"],
        attributes["Здание/сооружение (третий столбец)"],
        attributes["Номер здания/сооружения (третий столбец)"],
      ],
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const { changeFieldValue, form, fieldsNames, addressForDocs, addressChanged, initialValues } = this.props;
    if (addressChanged) {
      changeFieldValue(form, fieldsNames[attributes["Адрес для документов"]], addressForDocs);
    }

    // @ts-ignore
    const prevOktmo = prevProps.initialValues[attributes["ОКТМО"]];
    // @ts-ignore
    const currentOktmo = initialValues[attributes["ОКТМО"]];

    if (prevOktmo !== currentOktmo) {
      this.checkIfNeedToShowOktmoSupportMessage({ oktmo: +currentOktmo });
    }
  }

  handleSuffixMouseEnter = () => {
    this.setState({ tooltipVisible: true });
  };

  handleSuffixMouseLeave = () => {
    this.setState({ tooltipVisible: false });
  };

  checkIfNeedToShowOktmoSupportMessage({ oktmo }: { oktmo: number }) {
    const { form } = this.props;
    if (
      OKTMO_NUMBERS_WITH_SUPPORT_MESSAGE.includes(oktmo) &&
      FORM_NAMES_WITH_OKTMO_SUPPORT_MESSAGE.includes(form as Forms)
    ) {
      this.setState({ hasProblemOktmo: true });
    } else {
      this.setState({ hasProblemOktmo: false });
    }
  }

  async fillRegistrationField(selectedAddress: string) {
    // после каждого нового выбора подсказки из дадаты блокируем для ввода все поля
    this.setState({
      disabledFields: [
        attributes["Субъект"],
        attributes["Код терр. ФНС"],
        attributes["Код рег. органа"],
        attributes["Населенный пункт"],
        attributes["Наименование населенного пункта"],
        attributes["Элемент планировочной структуры"],
        attributes["Наименование элемента планировочной структуры"],
        attributes["Элемент улично-дорожной сети"],
        attributes["Наименование элемента улично-дорожной сети"],
        attributes["Здание/сооружение (первый столбец)"],
        attributes["Номер здания/сооружения (первый столбец)"],
        attributes["Здание/сооружение (второй столбец)"],
        attributes["Номер здания/сооружения (второй столбец)"],
        attributes["Здание/сооружение (третий столбец)"],
        attributes["Номер здания/сооружения (третий столбец)"],
      ],
    });

    const { autoCompleteData, changeFieldValue, loadSonoArea, getOktmoInfo, form, fieldsNames, extended } = this.props;

    const selectedHint = getSelectedDadataInfo({ selectedAddress, fieldsNames, autoCompleteData });
    const oktmo = +selectedHint?.data?.oktmo;

    this.checkIfNeedToShowOktmoSupportMessage({ oktmo });

    const addressData: any = await dadataAddressFormat(selectedHint.data, loadSonoArea, getOktmoInfo);

    if (addressData) {
      Object.entries(addressData).forEach(([attr, value]) => {
        if (!value) {
          // если из дадаты не пришло какое-то значение, то соотв. поле разблокируется для ввода
          this.setState({
            disabledFields: this.state.disabledFields.filter((field) => field !== attr),
          });
        }
      });

      changeFieldValue(form, attributes["ОКТМО"], oktmo.toString());
      changeFieldValue(form, fieldsNames[attributes["Субъект"]], addressData[attributes["Субъект"]]);
      changeFieldValue(form, fieldsNames[attributes["Код терр. ФНС"]], addressData[attributes["Код терр. ФНС"]]);
      changeFieldValue(form, fieldsNames[attributes["Код рег. органа"]], addressData[attributes["Код рег. органа"]]);

      changeFieldValue(
        form,
        fieldsNames[attributes["Населенный пункт"]],
        formatAddressObject(addressData[attributes["Населенный пункт"]])
      );
      changeFieldValue(
        form,
        fieldsNames[attributes["Наименование населенного пункта"]],
        addressData[attributes["Наименование населенного пункта"]]
      );

      if (
        !extended && // для рег. ИП пока не делаем :о
        !addressData[attributes["Наименование населенного пункта"]] &&
        isFederalCity(addressData[attributes["Наименование региона"]])
      ) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Населенный пункт"]],
          formatAddressObject(addressData[attributes["Регион (сокр.)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Наименование населенного пункта"]],
          addressData[attributes["Наименование региона"]]
        );
      }

      // эти поля заполняются, только если пришла полная информация
      const areaCorrect =
        addressData[attributes["Муниципальное образование (верхнее)"]] &&
        addressData[attributes["Наименование муниципального образования (верхнее)"]];
      if (areaCorrect) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Муниципальное образование (верхнее)"]],
          addressData[attributes["Муниципальное образование (верхнее)"]]
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Наименование муниципального образования (верхнее)"]],
          addressData[attributes["Наименование муниципального образования (верхнее)"]]
        );
      } else {
        changeFieldValue(form, fieldsNames[attributes["Муниципальное образование (верхнее)"]], "");
        changeFieldValue(form, fieldsNames[attributes["Наименование муниципального образования (верхнее)"]], "");
      }

      const subareaCorrect =
        addressData[attributes["Муниципальное образование (нижнее)"]] &&
        addressData[attributes["Наименование муниципального образования (нижнее)"]];
      if (subareaCorrect) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Муниципальное образование (нижнее)"]],
          addressData[attributes["Муниципальное образование (нижнее)"]]
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Наименование муниципального образования (нижнее)"]],
          addressData[attributes["Наименование муниципального образования (нижнее)"]]
        );
      } else {
        changeFieldValue(form, fieldsNames[attributes["Муниципальное образование (нижнее)"]], "");
        changeFieldValue(form, fieldsNames[attributes["Наименование муниципального образования (нижнее)"]], "");
      }

      // temp :o
      if (extended) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Элемент планировочной структуры"]],
          formatAddressObject(addressData[attributes["Элемент планировочной структуры"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Наименование элемента планировочной структуры"]],
          addressData[attributes["Наименование элемента планировочной структуры"]]
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Элемент улично-дорожной сети"]],
          formatAddressObject(addressData[attributes["Элемент улично-дорожной сети"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Наименование элемента улично-дорожной сети"]],
          addressData[attributes["Наименование элемента улично-дорожной сети"]]
        );
      } else {
        const square = formatAddressObject(addressData[attributes["Элемент планировочной структуры"]]);
        const street = formatAddressObject(addressData[attributes["Элемент улично-дорожной сети"]]);
        if (street) {
          changeFieldValue(form, fieldsNames[attributes["Элемент улично-дорожной сети"]], street);
          changeFieldValue(
            form,
            fieldsNames[attributes["Наименование элемента улично-дорожной сети"]],
            addressData[attributes["Наименование элемента улично-дорожной сети"]]
          );
        } else if (square) {
          changeFieldValue(form, fieldsNames[attributes["Элемент улично-дорожной сети"]], square);
          changeFieldValue(
            form,
            fieldsNames[attributes["Наименование элемента улично-дорожной сети"]],
            addressData[attributes["Наименование элемента планировочной структуры"]]
          );

          this.setState({
            disabledFields: [
              ...this.state.disabledFields,
              attributes["Элемент улично-дорожной сети"],
              attributes["Наименование элемента улично-дорожной сети"],
            ],
          });
        }
      }

      const house_1 = addressData[attributes["Номер здания/сооружения (первый столбец)"]];
      const house_2 = addressData[attributes["Номер здания/сооружения (второй столбец)"]];
      const house_3 = addressData[attributes["Номер здания/сооружения (третий столбец)"]];
      if (house_1) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Здание/сооружение (первый столбец)"]],
          formatAddressObject(addressData[attributes["Здание/сооружение (первый столбец)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]],
          addressData[attributes["Номер здания/сооружения (первый столбец)"]]
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Здание/сооружение (второй столбец)"]],
          formatAddressObject(addressData[attributes["Здание/сооружение (второй столбец)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]],
          addressData[attributes["Номер здания/сооружения (второй столбец)"]]
        );

        // temp :o
        changeFieldValue(
          form,
          fieldsNames[attributes["Здание/сооружение (третий столбец)"]],
          formatAddressObject(addressData[attributes["Здание/сооружение (третий столбец)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]],
          addressData[attributes["Номер здания/сооружения (третий столбец)"]]
        );
      } else if (house_2) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Здание/сооружение (первый столбец)"]],
          formatAddressObject(addressData[attributes["Здание/сооружение (второй столбец)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]],
          addressData[attributes["Номер здания/сооружения (второй столбец)"]]
        );

        // temp :o
        changeFieldValue(
          form,
          fieldsNames[attributes["Здание/сооружение (второй столбец)"]],
          formatAddressObject(addressData[attributes["Здание/сооружение (третий столбец)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]],
          addressData[attributes["Номер здания/сооружения (третий столбец)"]]
        );

        this.setState(
          {
            // перемещаем значение из второго столбца в первый, => второй столбец разблокируется
            disabledFields: [
              ...this.state.disabledFields.filter(
                (field) =>
                  field !== attributes["Здание/сооружение (второй столбец)"] &&
                  field !== attributes["Номер здания/сооружения (второй столбец)"]
              ),
              attributes["Здание/сооружение (первый столбец)"],
              attributes["Номер здания/сооружения (первый столбец)"],
            ],
          },
          () => {
            if (house_3) {
              // если есть значение в третьем столбце, перемещаем его во второй, а третий разблокируется
              this.setState({
                disabledFields: [
                  ...this.state.disabledFields.filter(
                    (field) =>
                      field !== attributes["Здание/сооружение (третий столбец)"] &&
                      field !== attributes["Номер здания/сооружения (третий столбец)"]
                  ),
                  attributes["Здание/сооружение (второй столбец)"],
                  attributes["Номер здания/сооружения (второй столбец)"],
                ],
              });
            }
          }
        );

        // temp :o
      } else if (house_3) {
        changeFieldValue(
          form,
          fieldsNames[attributes["Здание/сооружение (первый столбец)"]],
          formatAddressObject(addressData[attributes["Здание/сооружение (третий столбец)"]])
        );
        changeFieldValue(
          form,
          fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]],
          addressData[attributes["Номер здания/сооружения (третий столбец)"]]
        );

        this.setState({
          disabledFields: [
            ...this.state.disabledFields.filter(
              (field) =>
                field !== attributes["Здание/сооружение (второй столбец)"] &&
                field !== attributes["Номер здания/сооружения (второй столбец)"] &&
                field !== attributes["Здание/сооружение (третий столбец)"] &&
                field !== attributes["Номер здания/сооружения (третий столбец)"]
            ),
            attributes["Здание/сооружение (первый столбец)"],
            attributes["Номер здания/сооружения (первый столбец)"],
          ],
        });
      }

      changeFieldValue(form, fieldsNames[attributes["Квартира/офис"]], addressData[attributes["Квартира/офис"]]);
      changeFieldValue(
        form,
        fieldsNames[attributes["Номер квартиры/офиса"]],
        addressData[attributes["Номер квартиры/офиса"]]
      );

      changeFieldValue(
        form,
        fieldsNames[attributes["Наименование региона"]],
        addressData[attributes["Наименование региона"]]
      );
      changeFieldValue(
        form,
        fieldsNames[attributes["Регион (сокр.)"]],
        formatAddressObject(addressData[attributes["Регион (сокр.)"]])
      );
      changeFieldValue(form, fieldsNames[attributes["Регион (полн.)"]], addressData[attributes["Регион (полн.)"]]);

      this.setState({ house_fias_id: addressData.house_fias_id });

      // // после того, как заполнили все поля, запрашиваем информацию по планировочной структуре
      // later :o
      // const { addressWithoutFlat } = this.props;
      // const fiasData: any = await dadataAPI.post(`/suggest/fias`, {
      //   query: addressWithoutFlat
      // });
      // const additionalAddressData = fiasData?.suggestions[0]?.data;
      // if (additionalAddressData) {
      //   if (extended) {
      //     changeFieldValue(
      //       form,
      //       fieldsNames[attributes["Элемент планировочной структуры"]],
      //       additionalAddressData.planning_structure_type
      //     );
      //     changeFieldValue(
      //       form,
      //       fieldsNames[attributes["Наименование элемента планировочной структуры"]],
      //       additionalAddressData.planning_structure
      //     );
      //   }
      // } else {
      //   message.error(
      //     "Произошла ошибка определения адреса, напишите, пожалуйста, в чат поддержки и укажите адрес, который пытаетесь ввести"
      //   );
      // }
    } else {
      message.error(
        "Произошла ошибка определения адреса, напишите, пожалуйста, в чат поддержки и укажите адрес, который пытаетесь ввести"
      );
    }
  }

  setCityInputsFormat = (value: string) => {
    const { city } = this.props;
    return isFederalCity(city) ? "" : value;
  };

  getAddressSuffix = (house_fias_id: any) => {
    return house_fias_id === "initial_value" ? "" : house_fias_id ? "check" : "cross";
  };

  render() {
    const {
      title,
      handleSubmit,
      autoCompleteData,
      disabled: formDisabled,
      showTitle,
      showFinalAddress,
      showRegistrOrgan,
      custom_address,
      fieldsNames,
      extended,
      appartment_abbr,
      pom,
      region,
      userCompanyId,
      scene,
    } = this.props;
    const isUserAdmin = isAdmin(userCompanyId);
    const { house_fias_id, tooltipVisible, disabledFields } = this.state;
    const addressIcon = this.getAddressSuffix(house_fias_id);

    const canEditMunicipalFields = isUserAdmin;

    const oktmoErrorVisible = this.state.hasProblemOktmo && isScene("Заполнение", scene);

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            {showTitle && <h2 className={sf("title")}>{title}</h2>}
            <div
              className={classNames(
                sf("input-with-suffix", { "without-label": true }),
                sf("address-input"),
                b("address")
              )}
            >
              <Field
                placeholder="Введите адрес в свободной форме"
                name={fieldsNames[attributes["Полный адрес"]]}
                options={autoCompleteData[fieldsNames[attributes["Полный адрес"]] as "address"] || []}
                component={AAutoComplete}
                customBackfill={true}
                //@ts-ignore
                onSelect={this.fillRegistrationField}
                disabled={formDisabled}
              />
              {oktmoErrorVisible ? <span className={sf("error")}>{errorsTypes.unsupportedOktmo}</span> : null}
              <Tooltip visible={tooltipVisible} className={b("address-tooltip")} title={errorsTypes.invalidAddress} />
              <div
                className={classNames(
                  sf("input-suffix", { icon: addressIcon }),
                  b("address-suffix", { icon: addressIcon })
                )}
                onMouseEnter={() => (addressIcon === "cross" ? this.handleSuffixMouseEnter() : "")}
                onMouseLeave={() => (addressIcon === "cross" ? this.handleSuffixMouseLeave() : "")}
              ></div>
            </div>

            <div style={{ display: "none" }}>
              <Field name={fieldsNames[attributes["Наименование региона"]]} component={AInput} />
              <Field name={fieldsNames[attributes["Регион (сокр.)"]]} component={AInput} />
              <Field name={fieldsNames[attributes["Регион (полн.)"]]} component={AInput} />
              <Field name={fieldsNames[attributes["ОКТМО"]]} component={AInput} />
            </div>

            {showFinalAddress && (
              <div className={b("final-address-wrapper")}>
                <div className={b("final-address-label")}>Адрес, который будет показан в документах</div>
                <Field
                  name={fieldsNames[attributes["Адрес для документов"]]}
                  className={b("final-address-input")}
                  component={AInput}
                />

                {custom_address && (
                  <CopyToClipboard
                    text={custom_address}
                    onCopy={() => message.success("Адрес скопирован в буфер обмена")}
                  >
                    <div className={b("address-for-docs")}>{custom_address}</div>
                  </CopyToClipboard>
                )}
              </div>
            )}
            <div className={`${sf("inputs-grid")} ${b("inputs-grid")}`}>
              <Field
                title="Субъект:"
                name={fieldsNames[attributes["Субъект"]]}
                component={AInput}
                disabled={
                  formDisabled || (!isUserAdmin && disabledFields.some((field) => field === attributes["Субъект"]))
                }
                //@ts-ignore
                className={!showRegistrOrgan && b("long-field")}
              />
              {showRegistrOrgan && (
                <>
                  <Field
                    title="Код терр. ФНС:"
                    name={fieldsNames[attributes["Код терр. ФНС"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin && disabledFields.some((field) => field === attributes["Код терр. ФНС"]))
                    }
                  />

                  <Field
                    title="Код рег. органа:"
                    name={fieldsNames[attributes["Код рег. органа"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin && disabledFields.some((field) => field === attributes["Код рег. органа"]))
                    }
                  />
                </>
              )}
              {extended && (
                <>
                  <Field
                    showSearch={false}
                    name={fieldsNames[attributes["Муниципальное образование (верхнее)"]]}
                    className={classNames(b("long-field"), { [b("faded-field")]: !canEditMunicipalFields })}
                    component={ASelect}
                    title="Муниципальное образование:"
                    options={areaOptions}
                    disabled={!canEditMunicipalFields}
                  />
                  <Field
                    name={fieldsNames[attributes["Наименование муниципального образования (верхнее)"]]}
                    component={AInput}
                    disabled={!canEditMunicipalFields}
                    className={b("long-field", { "without-label": true })}
                    placeholder="Наименование"
                  />
                  <Field
                    showSearch={false}
                    name={fieldsNames[attributes["Муниципальное образование (нижнее)"]]}
                    className={classNames(b("long-field"), { [b("faded-field")]: !canEditMunicipalFields })}
                    component={ASelect}
                    title="Муниципальное образование:"
                    options={subareaOptions}
                    disabled={!canEditMunicipalFields}
                  />
                  <Field
                    name={fieldsNames[attributes["Наименование муниципального образования (нижнее)"]]}
                    component={AInput}
                    disabled={!canEditMunicipalFields}
                    className={b("long-field", { "without-label": true })}
                    placeholder="Наименование"
                  />
                </>
              )}

              <div className={b("long-field")}>
                <div className={b("inputs-set-title")}>Населенный пункт (город, деревня, село и прочее)</div>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    format={(value: string) => this.setCityInputsFormat(value)}
                    name={fieldsNames[attributes["Населенный пункт"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        (disabledFields.some((field) => field === attributes["Населенный пункт"]) ||
                          isFederalCity(region)))
                    }
                    className={b("view-input")}
                  />
                  <Field
                    format={(value: string) => this.setCityInputsFormat(value)}
                    name={fieldsNames[attributes["Наименование населенного пункта"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        (disabledFields.some((field) => field === attributes["Наименование населенного пункта"]) ||
                          isFederalCity(region)))
                    }
                    className={b("name-input")}
                  />
                </div>
              </div>

              {extended && (
                <div className={b("long-field")}>
                  <div className={b("inputs-set-title")}>Элемент планировочной структуры</div>
                  <div className={b("inputs-set-wrapper")}>
                    <Field
                      name={fieldsNames[attributes["Элемент планировочной структуры"]]}
                      component={AInput}
                      disabled={
                        formDisabled ||
                        (!isUserAdmin &&
                          disabledFields.some((field) => field === attributes["Элемент планировочной структуры"]))
                      }
                      className={b("view-input")}
                    />
                    <Field
                      name={fieldsNames[attributes["Наименование элемента планировочной структуры"]]}
                      component={AInput}
                      disabled={
                        formDisabled ||
                        (!isUserAdmin &&
                          disabledFields.some(
                            (field) => field === attributes["Наименование элемента планировочной структуры"]
                          ))
                      }
                      className={b("name-input")}
                    />
                  </div>
                </div>
              )}

              <div className={b("long-field")}>
                <div className={b("inputs-set-title")}>
                  {extended
                    ? "Элемент улично-дорожной сети"
                    : "Элемент улично-дорожной сети (улица, проспект и прочее)"}
                </div>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    name={fieldsNames[attributes["Элемент улично-дорожной сети"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some((field) => field === attributes["Элемент улично-дорожной сети"]))
                    }
                    className={b("view-input")}
                  />
                  <Field
                    name={fieldsNames[attributes["Наименование элемента улично-дорожной сети"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some(
                          (field) => field === attributes["Наименование элемента улично-дорожной сети"]
                        ))
                    }
                    className={b("name-input")}
                  />
                </div>
              </div>

              <div className={b("input-wrapper")}>
                <div className={b("inputs-set-title")}>Здание/сооружение</div>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    name={fieldsNames[attributes["Здание/сооружение (первый столбец)"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some((field) => field === attributes["Здание/сооружение (первый столбец)"]))
                    }
                    className={b("type-input")}
                    placeholder="тип"
                    ignoreTouched
                  />
                  <Field
                    name={fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some(
                          (field) => field === attributes["Номер здания/сооружения (первый столбец)"]
                        ))
                    }
                    className={b("number-input")}
                    placeholder="номер"
                    ignoreTouched
                  />
                </div>
              </div>

              <div className={b("input-wrapper")}>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    name={fieldsNames[attributes["Здание/сооружение (второй столбец)"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some((field) => field === attributes["Здание/сооружение (второй столбец)"]))
                    }
                    className={b("type-input")}
                    placeholder="тип"
                    ignoreTouched
                  />
                  <Field
                    name={fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some(
                          (field) => field === attributes["Номер здания/сооружения (второй столбец)"]
                        ))
                    }
                    className={b("number-input")}
                    placeholder="номер"
                    ignoreTouched
                  />
                </div>
              </div>

              <div className={b("input-wrapper")}>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    name={fieldsNames[attributes["Здание/сооружение (третий столбец)"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some((field) => field === attributes["Здание/сооружение (третий столбец)"]))
                    }
                    className={b("type-input")}
                    placeholder="тип"
                    ignoreTouched
                  />
                  <Field
                    name={fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]]}
                    component={AInput}
                    disabled={
                      formDisabled ||
                      (!isUserAdmin &&
                        disabledFields.some(
                          (field) => field === attributes["Номер здания/сооружения (третий столбец)"]
                        ))
                    }
                    className={b("number-input")}
                    placeholder="номер"
                    ignoreTouched
                  />
                </div>
              </div>

              <div className={b("input-wrapper")}>
                <div className={b("inputs-set-title")}>Квартира/офис</div>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    name={fieldsNames[attributes["Квартира/офис"]]}
                    component={AAutoComplete}
                    disabled={formDisabled}
                    className={b("type-input")}
                    options={flatOptions}
                    placeholder="тип"
                    ignoreTouched
                  />
                  <Field
                    name={fieldsNames[attributes["Номер квартиры/офиса"]]}
                    component={AInput}
                    disabled={formDisabled}
                    className={b("number-input")}
                    placeholder="номер"
                    ignoreTouched
                  />
                </div>
              </div>

              <div className={b("input-wrapper")}>
                <div className={b("inputs-set-title")}>Помещение</div>
                <div className={b("inputs-set-wrapper")}>
                  <Field
                    name={fieldsNames[attributes["Помещение"]]}
                    component={AAutoComplete}
                    disabled={formDisabled}
                    className={b("type-input")}
                    options={roomOptions}
                    placeholder="тип"
                    ignoreTouched
                  />
                  <Field
                    name={fieldsNames[attributes["Номер помещения"]]}
                    component={AInput}
                    disabled={formDisabled}
                    className={b("number-input")}
                    placeholder="номер"
                    ignoreTouched
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const formName = ownProps["form"];
  const { data, fieldsNames, extended } = ownProps;
  const { currentEntry } = state.entries;
  const formValues: any = formValueSelector(formName);
  const addressForDocs = getAddressString(state, formValues, fieldsNames);
  const addressWithoutFlat = extended ? getAddressString(state, formValues, fieldsNames, true) : "";
  const custom_address = isDirty(formName)(state)
    ? addressForDocs
    : formValues(state, fieldsNames[attributes["Адрес для документов"]]);
  const city = formValues(state, fieldsNames[attributes["Наименование населенного пункта"]]);
  const appartment_abbr = formValues(state, fieldsNames[attributes["Квартира/офис"]]);
  const pom = formValues(state, fieldsNames[attributes["Помещение"]]);
  const region = formValues(state, fieldsNames[attributes["Наименование региона"]]);
  const addressChanged = isDirty(formName)(state);

  const autoCompleteData = state.input.autoCompleteData[formName] || {};
  const { sono } = state.input;
  const initialValues = data ? getInitialValues(fieldsNames, data) : {};

  const userCompanyId = state.userReducer.user?.company?.id;
  const scene = currentEntry?.scene;

  return {
    addressChanged,
    autoCompleteData,
    sono,
    initialValues,
    addressForDocs,
    addressWithoutFlat,
    custom_address,
    city,
    appartment_abbr,
    pom,
    region,
    userCompanyId,
    scene,
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    loadSonoArea: (tax_office: string) => dispatch(loadSonoArea(tax_office)),
    getOktmoInfo: (code: string) => dispatch(getOktmoInfo(code)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  enableReinitialize: true,
  /* в файле getDataByLastName.ts мы диспатчим ititialize при выборе пользователя из БД бекенда
  (при вводе фамилии в автокомплите);
  из-за этого отключалась валидация - после выбора пользователя из БД перестали подгружаться 
  данные из дадаты для формы с адресом;
  чтобы это исправить, был имперически воткнут keepDirtyOnReinitialize */
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(RegistrationForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as RegistrationForm };
